import React, { Component } from 'react';
import Modal from 'react-modal';
import Cookies from 'js-cookie';

// ToDo

// Infra v1: https https://ahrefs.com/blog/what-is-https/
// test the cookie, it's doing something weird  aparent pe chrome. De cate ori se reseteaza intr-o zi, si vezi daca mai e fizic atunci cand se reseteaza

// leaderboard starts with previous day starting letter if we don't time it well. This happens if we let the old one at say 12:02, it registered, then we changed it
// so when we change it, we gotta delete the records of today but the old starting letter. Can this be done better?
// load starting letter value from an external config or another central single place

// database management SOP: the database will just grow daily, and entries for different days (and thus, different starting letter and sequences) will coexist
// database maintenance means bringing down the website at 9:00 pm PST, copying the database in archive, and starting fresh with a test that it works and writes the first record
// perform when DB rows at 150-200K
// on the nightly update:
// a) test that if we use the same starting letter as one of the earlier days it doesn't have issues and creates really an emtpy leaderboard

// Feature v1: undo button si ai 3 undo-uri?
// Analytics: sa facem tracking la vreun outcome?
// OE v1: log other data such as DB operations and size
// Refactoring v1: do we need to store ALL results? We might store unique (word, score) and link them to a list of players in the future
// Feature v1: user location based on IP, to show state
// Bug v1: when clicking the "?" after a play, it switches to the "you already played today"
// Feature v1 anti cheating against refresh: write in a cookie the date of play, and each time the user plays a letter the letter + where we are in the discarded list
// then, on render, load this cookie and restore state; or use React Context. In both cases, we need to also stream the sequence a number of characters equal to the number of characters that the player reached
// and we must also store the number of discards.
// Refactoring v1: split leaderboard from writewin, have a single controller for leaderboard
// Feature v2: randomly generate the string daily, and load it from configuration; it needs an algorithm to ensure proper letter density

function BottomPage(props) {

    //here - bottom of the page while still playing

    if (props.winCondition === undefined && props.lettersInWord < 6 && props.currentIndex > -1)
    //game in progress
    {
        return (
            <div className="letters">
                <div className="text-box">
                    <div className="inner">
                        <div className="sub-inner">
                            <p>{props.currentlettername}</p>
                            <span>{props.currentlettervalue}</span>
                        </div>
                        <div className="letters-left">
                            <ins>{50 - props.currentIndex} letters left</ins>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    // here we are in the transitory state
    if (props.winCondition === undefined && props.lettersInWord === 6) {

        return (
            <div className="letters">
                <div className="text-box">
                    <div className="inner">
                    </div>
                </div>
            </div>
        );
    }
}

function WordLetter(props) {
    return (
        <button value={props.index} onClick={props.onClick}>
            <div className="box">
                <p>{props.letter}</p>
                <span>{props.value}</span>
            </div>
        </button>
    );
}

function WordLetterWin(props) {
    return (
            <div className="boxWin">
                <p>{props.letter}</p>
                <span>{props.value}</span>
            </div>
    );
}

function WordLetterLose(props) {
    return (
        <div className="boxLose">
            <p>{props.letter}</p>
            <span>{props.value}</span>
        </div>
    );
}

class WinLayout extends Component {

    constructor(props) {
        super(props);
        this.state = { };
    }

    async getWriteWin(winWord, score) {
        
        const route = 'api/writewin/' + winWord + '/' + score;
       // console.log(route);
        const response = await fetch(route);

        const data = await response.json();
      
        this.setState({ myPosition: data.myPosition });
        this.setState({ myDate: data.myDate });
        this.setState({ wordWinner: data.wordWinner });
        this.setState({ scoreWinner: data.scoreWinner });
        this.setState({ dateWinner: data.dateWinner });
        if (data.scoreSecond !== -1) { this.setState({ scoreSecond: data.scoreSecond }); this.setState({ wordSecond: data.wordSecond }); this.setState({ dateSecond: data.dateSecond }); }
        if (data.scoreThird !== -1) { this.setState({ scoreThird: data.scoreThird }); this.setState({ wordThird: data.wordThird }); this.setState({ dateThird: data.dateThird }); }
        this.setState({ isAmongWinners: data.isAmongWinners });
    }

    async getLoseLeaderboard() {
        const response = await fetch('api/leaderboard');
        const data = await response.json();
        this.setState({ wordWinner: data.wordWinner });
        this.setState({ scoreWinner: data.scoreWinner });
        this.setState({ scoreSecond: data.scoreSecond });
        this.setState({ wordSecond: data.wordSecond });
        this.setState({ scoreThird: data.scoreThird });
        this.setState({ wordThird: data.wordThird });
        this.setState({ dateWinner: data.dateWinner })
        this.setState({ dateSecond: data.dateSecond })
        this.setState({ dateThird: data.dateThird })
        this.setState({ haveGottenLeaderboard: true });
    }

    writeCookieAfterPlay(wordWrite,isWinner, scoreWrite) {
        // at the end of the play, when the user has an outcome, override the timestamp in the cookie

        const cookieName = 'makeordleCookie';

        //const deviceInfo = deviceDetect();      
        //const ipAddress = deviceInfo.ip;
        //console.log("IP address " + ipAddress);

        const today = new Date();
        const todayEST = new Date(today.toLocaleString('en-US', { timeZone: 'America/New_York' }));

      //  console.log("About to set the cookie with timestamp " + todayEST + " and word " + wordWrite);

        // set the expiration date to one day from now
        const expirationDate = new Date(todayEST);
        expirationDate.setDate(todayEST.getDate() + 1);
 
        const cookieValue = JSON.stringify({
            timestamp: todayEST,
            lastWord: wordWrite,
            didWin: isWinner,
            score:scoreWrite  
        });

        Cookies.set(cookieName, cookieValue, { expires: expirationDate });
    }

   
    showWinningLeaderboard(myWord, score) {

        if (this.state.wordWinner !== undefined) {

            if (this.state.wordThird !== undefined) {

                if (this.state.isAmongWinners) {

                    var messageWin = "";

                    if (score === this.state.scoreWinner) { messageWin = "A word was made - highest score!"; }
                    else { messageWin = "A word was made - top 3 score"; }

                    // we have the top 3, and this is one of them - show top 3, with congrats
                    return (
                        <div>
                            <div className="found">
                                <p>{messageWin} </p>
                                <span>{this.props.length} Discarded letters</span>
                            </div>
                            <div className="final-score">
                                <button>Final Score: <b>{score}</b></button>
                            </div>
                            <div className="board">
                                <div className="inner">
                                    <span>Leaderboard</span>
                                </div>
                                <div className="sub-inner">
                                    <span><b> 1: {this.state.wordWinner} {this.state.scoreWinner}, at {this.state.dateWinner}</b> </span>
                                    <span><b> 2: {this.state.wordSecond} {this.state.scoreSecond}, at {this.state.dateSecond}</b> </span>
                                    <span><b> 3: {this.state.wordThird} {this.state.scoreThird}, at {this.state.dateThird}</b> </span>
                                </div>
                            </div>
                        </div>
                    );
                }
                else {
                    // we have the top 3, and this is NOT one of them - show all 4
                    return (
                        <div>
                            <div className="found">
                                <p>A word was made! </p>
                                <span>{this.props.length} Discarded letters</span>
                            </div>
                            <div className="final-score">
                                <button>Final Score: <b>{score}</b></button>
                            </div>
                            <div className="board">
                                <div className="inner">
                                    <span>Leaderboard</span>
                                </div>
                                <div className="sub-inner">
                                    <span><b> 1: {this.state.wordWinner} {this.state.scoreWinner}, at {this.state.dateWinner}</b>  </span>
                                    <span><b> 2: {this.state.wordSecond} {this.state.scoreSecond}, at {this.state.dateSecond} </b> </span>
                                    <span><b> 3: {this.state.wordThird} {this.state.scoreThird}, at {this.state.dateThird}</b>  </span>
                                    <span>&nbsp;</span>
                                    <span><b> {this.state.myPosition}: {myWord} {score}, at {this.state.myDate} </b></span>
                                </div>
                            </div>
                        </div>
                    );
                }

            }

            else if (this.state.wordSecond !== undefined) {
                // we have top 2, by necessity we are one of them

                var messageWin2 = "";

                if (score === this.state.scoreWinner) { messageWin2 = "A word was made - highest score!"; }
                else { messageWin2 = "A word was made - top 2 score"; }

                return (
                    <div>
                        <div className="found">
                            <p>{messageWin2}</p>
                            <span>{this.props.length} Discarded letters</span>
                        </div>
                        <div className="final-score">
                            <button>Final Score: <b>{score}</b></button>
                        </div>
                        <div className="board">
                            <div className="inner">
                                <span>Leaderboard</span>
                            </div>
                            <div className="sub-inner">
                                <span><b>1: {this.state.wordWinner} {this.state.scoreWinner}, at {this.state.dateWinner}</b>  </span>
                                <span><b>2: {this.state.wordSecond} {this.state.scoreSecond}, at {this.state.dateSecond}</b> </span>
                            </div>
                        </div>
                    </div>
                );
            }
            else {
                // we only have the top result, by necessity it's us
                return (
                    <div>
                        <div className="found">
                            <p>A word was made - highest score! </p>
                            <span>{this.props.length} Discarded letters</span>
                        </div>
                        <div className="final-score">
                            <button>Final Score: <b>{score}</b></button>
                        </div>
                        <div className="board">
                            <div className="inner">
                                <span>Leaderboard</span>
                            </div>
                            <div className="sub-inner">
                                <span><b> 1: {this.state.wordWinner} {this.state.scoreWinner}, at {this.state.dateWinner}</b>  </span>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }

    render() {
        
        const score = this.props.filledLettersValues.reduce((sum, value) => sum = sum + value, 0) * 5 - this.props.length;

       // console.log("winCondition:" + this.props.winCondition);
       // console.log("wordThird:" + this.state.wordThird);
      //  console.log("wordSecond:" + this.state.wordSecond);

        if (this.props.winCondition === true) {

            const myWord = this.props.filledLetters.toString().replace(/,/g, '').toLowerCase();


            this.writeCookieAfterPlay(myWord, true, score);

            if (this.state.myPosition === undefined) {
                this.getWriteWin(myWord, score);
            }

            // do not continue until fully loaded

            return (this.showWinningLeaderboard(myWord, score));
        }

        if (this.props.winCondition === undefined && this.props.lettersInWord < 6 && this.props.currentIndex > -1)
        {
            // here game is still in progress
            // <span><b>Mantis 270</b> (Jan 6, 10:30am) from <ins> Seattle</ins></span>
            return (
                <div>
                    <div className="discard-btn">
                        <button onClick={this.props.onClick}>Discard</button>
                    </div>
                    <div className="current-score">
                        <button>Current Score: <b>{score}</b></button>
                    </div>
                </div>); 
        }
        

        if (this.props.winCondition === false && this.props.lettersInWord === 6) {
            // here lose situation
            const myWord = this.props.filledLetters.toString().replace(/,/g, '').toLowerCase();

       

            this.writeCookieAfterPlay(myWord, false, score);
           

            if (this.state.haveGottenLeaderboard === undefined) {
                this.getLoseLeaderboard();
            }
            // console.log("I'm displaying lose due to unknown word")
           
           // console.log("wordWinner is " + this.state.wordWinner);
            if ((this.state.wordThird !== "") && (this.state.wordThird !== undefined))

            {
               
                return (
                    <div>
                        <div className="points">
                            <span>Not a known 6 letter word - <b>no points</b></span>
                        </div>
                        <div className="board">
                            <div className="inner">
                                <span>Leaderboard</span>
                            </div>

                            <div className="sub-inner">
                                <span><b> 1: {this.state.wordWinner} {this.state.scoreWinner}, at {this.state.dateWinner}</b></span>
                                <span><b> 2: {this.state.wordSecond} {this.state.scoreSecond}, at {this.state.dateSecond}</b></span>
                                <span><b> 3: {this.state.wordThird} {this.state.scoreThird}, at {this.state.dateThird}</b></span>
                             </div>
                        </div>
                    </div>
                );
            }

            else if ((this.state.wordSecond !== "") && (this.state.wordSecond !== undefined))  {
                return (
                    <div>
                        <div className="points">
                            <span>Not a known 6 letter word - <b>no points</b></span>
                        </div>
                        <div className="board">
                            <div className="inner">
                                <span>Leaderboard</span>
                            </div>
                            <div className="sub-inner">
                                <span><b> 1: {this.state.wordWinner} {this.state.scoreWinner}, at {this.state.dateWinner}</b> </span>
                                <span><b> 2: {this.state.wordSecond} {this.state.scoreSecond}, at {this.state.dateSecond} </b></span>
                            </div>
                        </div>
                    </div>
                );
            }

            else if ((this.state.wordWinner !== "") && (this.state.wordWinner !== undefined)) {
                return (
                    <div>
                        <div className="points">
                            <span>Not a known 6 letter word - <b>no points</b></span>
                        </div>
                        <div className="board">
                            <div className="inner">
                                <span>Leaderboard</span>
                            </div>
                            <div className="sub-inner">
                                <span><b> 1: {this.state.wordWinner} {this.state.scoreWinner}, at {this.state.dateWinner}</b></span>
                            </div>
                        </div>
                    </div>
                );
            }

            else {
            
                return (
                    <div>
                        <div className="points">
                            <span>Not a known 6 letter word - <b>no points</b></span>
                        </div>
                    </div>);
            }
        }

        // here lose situation
        if (this.props.winCondition === undefined && this.props.currentIndex <= -1) { 
            const myWord = this.props.filledLetters.toString().replace(/,/g, '').toLowerCase();

           
            this.writeCookieAfterPlay(myWord, false, score);
        

            if (this.state.haveGottenLeaderboard === undefined) {
                this.getLoseLeaderboard();
            }

            if ((this.state.wordThird !== undefined) && (this.state.wordThird !== "")) {
                return (
                    <div>
                        <div className="points">
                            <span> Out of letters - <b>no points</b></span>
                        </div>
                        <div className="board">
                            <div className="inner">
                                <span>Leaderboard</span>
                            </div>
                            <div className="sub-inner">
                                <span><b> 1: {this.state.wordWinner} {this.state.scoreWinner}, at {this.state.dateWinner}</b> </span>
                                <span><b> 2: {this.state.wordSecond} {this.state.scoreSecond}, at {this.state.dateSecond}</b> </span>
                                <span><b> 3: {this.state.wordThird} {this.state.scoreThird}, at {this.state.dateThird}</b></span>
                            </div>
                        </div>
                     </div>
                );
            }

            else if ((this.state.wordSecond !== undefined) && (this.state.wordSecond !== "")) {
                return (
                    <div>
                        <div className="points">
                            <span> Out of letters - <b>no points</b></span>
                        </div>  
                        <div className="board">
                            <div className="inner">
                                <span>Leaderboard</span>
                            </div>
                            <div className="sub-inner">
                                <span><b> 1: {this.state.wordWinner} {this.state.scoreWinner}, at {this.state.dateWinner}</b></span>
                                <span><b> 2: {this.state.wordSecond} {this.state.scoreSecond}, at {this.state.dateSecond}</b></span>
                             </div>
                        </div>
                     </div>
                );
            }

            else if ((this.state.wordWinner !== undefined) && (this.state.wordWinner !== "")) {
                return (
                    <div>
                        <div className="points">
                            <span> Out of letters - <b>no points</b></span>
                        </div>  
                        <div className="board">
                            <div className="inner">
                                <span>Leaderboard</span>
                            </div>
                            <div className="sub-inner">
                                <span><b> 1: {this.state.wordWinner} {this.state.scoreWinner}, at {this.state.dateWinner}</b></span>
                            </div>
                        </div>
                    </div>
                );
            }

            else {
                return (
                    <div>
                        <div className="points">
                            <span> Out of letters - <b>no points</b></span>
                        </div>  
                    </div>);
            }
        }

    }  

}

class Word extends Component {
    renderLetter(i) {

        if (this.props.winCondition === true) {
           // console.log("branch1");
            return (
                <WordLetterWin
                    letter={this.props.myLetters[i]}
                    value={this.props.myValues[i]}
                />
            );
        }

        if (this.props.winCondition === undefined && this.props.currentIndex === -1) {
           //console.log("branch2");
            return (
                <WordLetterLose
                    letter={this.props.myLetters[i]}
                    value={this.props.myValues[i]}
                />
            );
        }
        //here game still in progress
        if (this.props.winCondition === undefined && this.props.lettersInWord < 6) {
           // console.log("branch3");
            return (
            <WordLetter
                index={i}
                letter={this.props.myLetters[i]}
                value={this.props.myValues[i]}
                onClick={() => this.props.onClick(i)}
            />
            );
            }
   
        if (this.props.winCondition === false && this.props.lettersInWord === 6) {
           // console.log("branch4");
            return (
                <WordLetterLose
                    letter={this.props.myLetters[i]}
                    value={this.props.myValues[i]}
                />
            );
        }

        // here game is about to end, but the state variable winCondition hasn't been written yet
        // this is a transitory state, defaulting to normal word letter so that we don't get the flicker
        if (this.props.winCondition === undefined && this.props.lettersInWord === 6) {
           // console.log("branch5");
            return (
                <WordLetter
                    index={i}
                    letter={this.props.myLetters[i]}
                    value={this.props.myValues[i]}
                    onClick={() => this.props.onClick(i)}
                />
            );
        }

    }
    render()
    {
        return (
        <>
        { this.renderLetter(1)}
        { this.renderLetter(2) }
        { this.renderLetter(3) }
        { this.renderLetter(4) }
        {this.renderLetter(5)}
        </>
        );
     }
}

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = { modalIsOpen: false, lettersInWord: 1, currentIndex: 0, currentlettername: '', currentlettervalue: 0, filledLetters: Array(6).fill(null), filledLettersValues: Array(6).fill(null), discardedLetters: Array(0).fill(null) };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

    }

    openModal(event) {
        event.preventDefault();
        this.setState({ modalIsOpen: true });
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }



    handleClick(index) {
        const currentWord = this.state.filledLetters.slice();
        if (currentWord[index]) { return; }

        //this.setState({ filledLetters[index]: this.state.currentlettername });
       // this.setState({ filledLettersValues[index]: this.state.currentlettervalue });

        this.state.filledLetters[index] = this.state.currentlettername;
        this.state.filledLettersValues[index] = this.state.currentlettervalue;

        // test for 6 letter word on each successful click
        this.setState({ lettersInWord: this.state.lettersInWord + 1 });

        if (this.state.lettersInWord === 5) {
            //  console.log(this.state.filledLetters);
            //   console.log(this.state.filledLettersValues);

            this.getTestWord();


        }
        else {

            this.getStreamLetter();
        }
    }

    handleDiscard() {
        var newArray = this.state.discardedLetters.slice();
        newArray.push(this.state.currentlettername);
        this.setState({ discardedLetters: newArray })
        this.getStreamLetter();
    }

    componentDidMount() {
        //get init letter
        this.getFirstLetter();
        //stream first letter
        this.getStreamLetter();
    }

    showLeaderboardCantPlay(myWord, score) {

        if (this.state.wordWinner !== undefined) {

            if (this.state.wordThird !== undefined) {

                if (this.state.isAmongWinners) {

                    var messageWin = "";

                    if (score === this.state.scoreWinner) { messageWin = "Highest score!"; }
                    else { messageWin = "Top 3 score!"; }

                    // we have the top 3, and this is one of them - show top 3, with congrats
                    return (
                        <div>
                            <div className="found">
                                <p>{messageWin} </p>
                            </div>
                            <div className="board">
                                <div className="inner">
                                    <span>Leaderboard</span>
                                </div>
                                <div className="sub-inner">
                                    <span><b> 1: {this.state.wordWinner} {this.state.scoreWinner}, at {this.state.dateWinner}</b> </span>
                                    <span><b> 2: {this.state.wordSecond} {this.state.scoreSecond}, at {this.state.dateSecond}</b> </span>
                                    <span><b> 3: {this.state.wordThird} {this.state.scoreThird}, at {this.state.dateThird}</b> </span>
                                </div>
                            </div>
                        </div>
                    );

                }
                else {
                    // we have the top 3, and this is NOT one of them - show all 4
                    return (
                        <div>
                            <div className="board">
                                <div className="inner">
                                    <span>Leaderboard</span>
                                </div>
                                <div className="sub-inner">
                                    <span><b> 1: {this.state.wordWinner} {this.state.scoreWinner}, at {this.state.dateWinner}</b>  </span>
                                    <span><b> 2: {this.state.wordSecond} {this.state.scoreSecond}, at {this.state.dateSecond} </b> </span>
                                    <span><b> 3: {this.state.wordThird} {this.state.scoreThird}, at {this.state.dateThird}</b>  </span>
                                    <span>&nbsp;</span>
                                    <span><b> {this.state.myPosition}: {myWord} {score}, at {this.state.myDate} </b></span>
                                </div>
                            </div>
                        </div>
                    );
                }

            }

            else if (this.state.wordSecond !== undefined) {
                // we have top 2, by necessity we are one of them

                var messageWin2 = "";

                if (score === this.state.scoreWinner) { messageWin2 = "Highest score!"; }
                else { messageWin2 = "Top 2 score!"; }
                return (
                    <div>
                        <div className="found">
                            <p>{messageWin2}</p>

                        </div>

                        <div className="board">
                            <div className="inner">
                                <span>Leaderboard</span>
                            </div>
                            <div className="sub-inner">
                                <span><b>1: {this.state.wordWinner} {this.state.scoreWinner}, at {this.state.dateWinner}</b>  </span>
                                <span><b>2: {this.state.wordSecond} {this.state.scoreSecond}, at {this.state.dateSecond}</b> </span>
                            </div>
                        </div>
                    </div>
                );
            }
            else {
                // we only have the top result, by necessity it's us
                return (
                    <div>
                        <div className="found">
                            <p>Highest score! </p>

                        </div>

                        <div className="board">
                            <div className="inner">
                                <span>Leaderboard</span>
                            </div>
                            <div className="sub-inner">
                                <span><b> 1: {this.state.wordWinner} {this.state.scoreWinner}, at {this.state.dateWinner}</b>  </span>
                            </div>
                        </div>
                    </div>
                );
            }
        }


    }

    showLeaderboardCantPlayLose() {

        if ((this.state.wordThird !== "") && (this.state.wordThird !== undefined)) {

            return (
                <div>   
                    <div className="board">
                        <div className="inner">
                            <span>Leaderboard</span>
                        </div>

                        <div className="sub-inner">
                            <span><b> 1: {this.state.wordWinner} {this.state.scoreWinner}, at {this.state.dateWinner}</b></span>
                            <span><b> 2: {this.state.wordSecond} {this.state.scoreSecond}, at {this.state.dateSecond}</b></span>
                            <span><b> 3: {this.state.wordThird} {this.state.scoreThird}, at {this.state.dateThird}</b></span>
                        </div>
                    </div>
                </div>
            );
        }

        else if ((this.state.wordSecond !== "") && (this.state.wordSecond !== undefined)) {
            return (
                <div>  
                    <div className="board">
                        <div className="inner">
                            <span>Leaderboard</span>
                        </div>
                        <div className="sub-inner">
                            <span><b> 1: {this.state.wordWinner} {this.state.scoreWinner}, at {this.state.dateWinner}</b> </span>
                            <span><b> 2: {this.state.wordSecond} {this.state.scoreSecond}, at {this.state.dateSecond} </b></span>
                        </div>
                    </div>
                </div>
            );
        }

        else if ((this.state.wordWinner !== "") && (this.state.wordWinner !== undefined)) {
            return (
                <div> 
                    <div className="board">
                        <div className="inner">
                            <span>Leaderboard</span>
                        </div>
                        <div className="sub-inner">
                            <span><b> 1: {this.state.wordWinner} {this.state.scoreWinner}, at {this.state.dateWinner}</b></span>
                        </div>
                    </div>
                </div>
            );
        }

        else {

            return ("");
                
        }
    }

    async getDumbLeaderboardWin(winWord, score) {

        const route = 'api/dumbleaderboard/' + winWord + '/' + score;
        // console.log(route);
        const response = await fetch(route);

        const data = await response.json();

        this.setState({ myPosition: data.myPosition });
        this.setState({ myDate: data.myDate });
        this.setState({ wordWinner: data.wordWinner });
        this.setState({ scoreWinner: data.scoreWinner });
        this.setState({ dateWinner: data.dateWinner });
        if (data.scoreSecond !== -1) { this.setState({ scoreSecond: data.scoreSecond }); this.setState({ wordSecond: data.wordSecond }); this.setState({ dateSecond: data.dateSecond }); }
        if (data.scoreThird !== -1) { this.setState({ scoreThird: data.scoreThird }); this.setState({ wordThird: data.wordThird }); this.setState({ dateThird: data.dateThird }); }
        this.setState({ isAmongWinners: data.isAmongWinners });
        this.setState({ haveGottenLeaderboard: true });
    }

    async getDumbLeaderboardLose() {
        const response = await fetch('api/leaderboard');
        const data = await response.json();
        this.setState({ wordWinner: data.wordWinner });
        this.setState({ scoreWinner: data.scoreWinner });
        this.setState({ scoreSecond: data.scoreSecond });
        this.setState({ wordSecond: data.wordSecond });
        this.setState({ scoreThird: data.scoreThird });
        this.setState({ wordThird: data.wordThird });
        this.setState({ dateWinner: data.dateWinner });
        this.setState({ dateSecond: data.dateSecond });
        this.setState({ dateThird: data.dateThird });
        this.setState({ haveGottenLeaderboard: true });
    }


    render() {
        //const deviceInfo = deviceDetect();
        //const ipAddress = deviceInfo.ip;

        const logo = require('./img/logo.png');

        const cookieName = 'makeordleCookie';
        const today = new Date();
        const todayEST = new Date(today.toLocaleString('en-US', { timeZone: 'America/New_York' }));

        if (!Cookies.get(cookieName)) { }// cookie does not exist; resume play, cookie will be set in a separate method upon game outcome

        else {
            // console.log("Cookie exists");
            // check if the user already played today

            /* REMOVE HERE */
            const userCookie = Cookies.get(cookieName);

            //console.log(userCookie);

            const cookieData = JSON.parse(userCookie);
            const timestampString = cookieData.timestamp;
            const timestampDate = new Date(timestampString);

            const lastPlayedWord = cookieData.lastWord;
            const didWin = cookieData.didWin;
            const score = cookieData.score;

          //  console.log(timestampDate);
           // console.log(lastPlayedWord);

            if (timestampDate.getFullYear() === todayEST.getFullYear() &&
                timestampDate.getMonth() === todayEST.getMonth() &&
                timestampDate.getDate() === todayEST.getDate()) {
                // the user has already played today
                //<link href="css/style.css" type="text/css" />
                // <link rel="stylesheet" href="/css/style.css"></link>

                // compute number of hours left   
                const midnightTonight = new Date(todayEST);
                midnightTonight.setHours(24, 0, 0, 0); // set to midnight tonight

                const diffMillis = midnightTonight - todayEST;
                const diffMinutes = Math.floor(diffMillis / 60000); // 1 minute = 60000 milliseconds
                const diffHours = Math.floor(diffMinutes / 60);
                const diffMinutesRemaining = diffMinutes % 60;

                const diffFormatted = `${diffHours.toString().padStart(2, '0')}:${diffMinutesRemaining.toString().padStart(2, '0')}`;

                let lastPlayedMessage = "";
                let leaderboardDisplay = "";

                if (didWin === true) {
                    lastPlayedMessage = <div className="alreadyplayedWord">Your word was <b>{lastPlayedWord}</b></div>

                    // <p style={{ fontFamily: "Poppins", fontSize: "40px" }}>, score <b>{score}</b> </p>
                    //this.getDumbLeaderboardWin(lastPlayedWord, score);

                    if (this.state.haveGottenLeaderboard === undefined) {
                        this.getDumbLeaderboardWin(lastPlayedWord, score);
                    }

                    leaderboardDisplay = this.showLeaderboardCantPlay(lastPlayedWord, score);
                }
                else {
                    lastPlayedMessage = <div className="alreadyplayedWord">You didn't form a valid word today</div>
                    //<p style={{ fontFamily: "Poppins", fontSize: "40px" }}>You didn't form a valid word today </p>
                    //this.getDumbLeaderboardLose();

                    if (this.state.haveGottenLeaderboard === undefined) {
                        this.getDumbLeaderboardLose();
                        
                    }

                    leaderboardDisplay = this.showLeaderboardCantPlayLose();
                }

                // <link rel="stylesheet" href="css/style.css" type="text/html" />
                //  <head>
                //            <meta charSet="UTF-8"></meta>
                //           <meta httpEquiv="X-UA-Compatible" content="IE=edge"></meta>
                //              <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                //           <title>Makeordle</title>
                //       </head >


                return (

                    
                    <section className="main">
                       

                        <div className="container">
                            <div className="logo-sec">
                                <img src={logo} className="img-fluid" alt="" />
                            </div>
                            <div className="circle">

                                <button onClick={this.openModal}><b>?</b></button>
                               

                                    <Modal isOpen={this.state.modalIsOpen} style={{
                                        content: {
                                            fontSize: '16px', width: '50vw', height: '38vh', position: 'relative', top: '45%',
                                            left: '20%',
                                            transform: 'translate(-40%, -45%)', margin: 'auto'
                                        }
                                    }}>


                                    <div>
                                        <b>How to play Makeordle - let's make a word!</b>
                                        
                                        <p>50 letters come in sequence. You need to make a 6 letter word. The first letter is fixed.</p>
                                        <p> You score each letter value x 5. You can discard a letter but lose a point.
                                            If you form a word, you get into the leaderboard.
                                            You can play once a day, there will be a new starting letter, sequence and leaderboard tomorrow. </p>
                                        <p>Good luck! </p>
                                        <p className="email">contact@makeordle.com</p>

                                        <button onClick={this.closeModal}>Close</button>
                                    </div>
                                </Modal>

                            </div>
                        </div>

                        <div className="alreadyplayedMain">You already played today</div>
                        {lastPlayedMessage}
                        <p style={{ fontFamily: "Poppins", fontSize: "30px" }}>Next play available at midnight EST - {diffFormatted} hours left</p>
                        {leaderboardDisplay}


                    </section>

                )
            }
              
          /* REMOVE HERE */
        } 

        return (

            <section className="main">

                <div className="container">
                    <div className="logo-sec">
                        <img src={logo} className="img-fluid" alt="" />
                    </div>
                    <div className="circle">

                        <button onClick={this.openModal}>?</button>
                        <Modal isOpen={this.state.modalIsOpen} style={{
                            content: {
                                fontSize: '16px', width: '50vw', height: '38vh', position: 'relative', top: '45%',
                                left: '20%',
                                transform: 'translate(-40%, -45%)', margin: 'auto'
                            }
                        }}>

                            <div>
                                <b>How to play Makeordle - let's make a word!</b>

                                <p>50 letters come in sequence. You need to make a 6 letter word. The first letter is fixed.</p>
                                <p> You score each letter value x 5. You can discard a letter but lose a point.
                                    If you form a word, you get into the leaderboard.
                                    You can play once a day, there will be a new starting letter, sequence and leaderboard tomorrow. </p>
                                <p>Good luck! </p>
                                <p className="email">contact@makeordle.com</p>

                                <button onClick={this.closeModal}>Close</button>
                            </div>
                        </Modal>

                    </div>

                </div>



                <div className="boxes">

                    <div className="box">
                        <p>
                            {this.state.filledLetters[0]}
                        </p>
                        <span>{this.state.filledLettersValues[0]}</span>
                    </div>

                    <Word
                        myLetters={this.state.filledLetters}
                        myValues={this.state.filledLettersValues}
                        winCondition={this.state.winCondition}
                        lettersInWord={this.state.lettersInWord}
                        currentIndex={this.state.currentIndex}
                        onClick={i => this.handleClick(i)} />
                </div>
                <div className="content">
                    <BottomPage
                        winCondition={this.state.winCondition}
                        lettersInWord={this.state.lettersInWord}
                        currentlettername={this.state.currentlettername}
                        currentlettervalue={this.state.currentlettervalue}
                        currentIndex={this.state.currentIndex} />

                    <WinLayout
                        length={this.state.discardedLetters.length}
                        winCondition={this.state.winCondition}
                        lettersInWord={this.state.lettersInWord}
                        currentIndex={this.state.currentIndex}
                        filledLetters={this.state.filledLetters}
                        filledLettersValues={this.state.filledLettersValues}

                        onClick={() => this.handleDiscard()}
                    />

                </div>

            </section>

        );

    }

    async getFirstLetter() {
        const response = await fetch('api/startingletter');
        const data = await response.json();

        let tempArray = [...this.state.filledLetters];
        tempArray[0] = data.letterName;
        this.setState({ filledLetters: tempArray });

        tempArray = [...this.state.filledLetters];
        tempArray[0] = data.letterValue;
        this.setState({ filledLettersValues: tempArray });

     }

    async getStreamLetter() {
        if (this.state.currentIndex <50) {
            const response = await fetch('api/streamletter/' + this.state.currentIndex);
            const data = await response.json();
            this.setState({ currentlettername: data.letterName });
            this.setState({ currentlettervalue: data.letterValue });
            this.setState({ currentIndex: this.state.currentIndex + 1 });
        }
        else {
            this.setState({ currentIndex: -1 });
        }

    }

    async getTestWord()

    {
        const wordToSend = this.state.filledLetters.toString().replace(/,/g, '');
        //console.log("I am testing the word " + wordToSend);
        const response = await fetch(('api/testword/' + wordToSend));
        const data = await response.json();

        this.setState({ winCondition: data });
  
    }

   
      /*  fetch(resourceURI)
            .then(res => {
                // Here you can process the response data
            }).catch(err => {


                // Here you can handle request errors if any
            });
*/
    }


 